<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-clay overflow-auto">
        <div 
            v-if="confirmInProgress"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-clay-light text-white p-8 flex flex-col rounded-t-lg md:rounded-none">
                <h2 class="text-2xl font-normal mb-2">{{$t('auth.confirmEmail')}}</h2>
                <p>{{$t('auth.confirmEmailText')}}</p>
            </div>
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="resetPasswordSubmit()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        :label="$t('auth.email')"
                        name="email"
                        :placeholder="$t('auth.email')"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="confirmCode"
                        :label="$t('auth.verificationCode')"
                        name="confirmCode"
                        :placeholder="$t('auth.verificationCode')"
                        class="w-full"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        :label="$t('auth.newPassword')"
                        name="newPassword"
                        :placeholder="$t('auth.newPassword')"
                        class="w-full "
                        type="password"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        {{$t('auth.setNewPassword')}}
                    </button>
                </form>
                
                <div class="text-center">
                    <router-link :to="{ name: 'signin'}">
                        {{$t('auth.returnToSignIn')}}
                    </router-link>
                </div>
            </div>
        </div>
        <div 
            v-else-if="!confirmInProgress"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-clay-light text-white p-8 flex flex-col rounded-t-lg md:rounded-none">
                <h2 class="text-2xl font-normal mb-2 leading-tight">{{$t('auth.resetYourPassword')}}</h2>
                <p>{{$t('auth.resetYourPasswordText')}}</p>
            </div>
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="resetPasswordEmail()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        <!-- <h2 class="text-white">Error</h2> -->
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        :label="$t('auth.email')"
                        name="email"
                        :placeholder="$t('auth.email')"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        {{$t('general.continue')}}
                    </button>
                </form>
                
                <div class="text-center">
                    <router-link :to="{ name: 'signin'}">
                        {{$t('auth.returnToSignIn')}}
                    </router-link>
                </div>
            </div>  
        </div>
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        data() {
            return {
                email: null,
                error: null,
                confirmInProgress: null,
                confirmCode: null,
                password: null,
                loading: false,
            }
        },
    }
</script>
